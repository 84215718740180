.layoutContainer {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  overflow: hidden; // 添加这行

  &::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
  }
  -ms-overflow-x: hidden;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.sidebarNav a {
  text-decoration: none !important;
}
.animationWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.sidebarNav {
  width: 150px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  .navItem {
    position: relative;
    padding: 15px 20px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;

    .circle {
      width: 12px;
      height: 12px;
      margin-right: 10px;
      opacity: 0;
      transition: all 0.3s ease;
    }

    .square {
      width: 12px;
      height: 12px;
      margin-left: auto;
      opacity: 0;
      transition: all 0.3s ease;
    }

    &.active {
      background: linear-gradient(90deg, #C34781 0%, #242424 100%);
      color: #fff;

      .circle {
        opacity: 1;
        border: 2px solid #C34781;
        border-radius: 50%;
        box-sizing: border-box;
      }

      .square {
        opacity: 1;
        border: 2px solid #C34781;
        box-sizing: border-box;
      }
    }

    &:hover:not(.active) {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
      text-decoration: none;
    }
  }

  .navLink {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    padding: 15px 20px;
    opacity: 0.8;
    transition: all 0.3s ease;

    &:hover {
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.1);
      text-decoration: none;
    }
  }
}

.mainContent {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-x: hidden;
  scrollbar-width: none;
}

.shareButtons {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 252px;
  z-index: 10;

  .shareAreas {
    height: 252px;
    width: 48px;
    position: absolute;
    top: 0;
    left: 0;
    
    div {
      width: 100%;
      height: 25%;
      cursor: pointer;
    }
  }
}

.shareImage {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.enterCoupon {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  object-fit: contain;
}

// 添加鼠标跟随效果的样式
.mouseTrail {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 9999;
}

.trail {
  stroke: #C34781;
  fill: none;
  transition: opacity 0.2s ease;
}

@keyframes fadeOut {
  0% {
    opacity: 0.8;
    stroke-width: 2px;
  }
  100% {
    opacity: 0;
    stroke-width: 1px;
  }
}

.layout {
  min-height: 100vh;
  background: #000;
}

.nav {
  position: fixed;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 20px;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    opacity: 0.7;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}

.video_popup {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  background: rgba($color: #000000, $alpha: .8);
  .video {
    height: 90vh;
    padding: .1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .video_close {
    position: absolute;
    right: .25rem;
    top: .25rem;
    color: #fff;
    font-size: .24rem;
    cursor: pointer;
  }
}
