
.EventInformationSwiper  {
  height: 120%;
    .swiper-pagination {
      top: 1.4rem !important;
    }
    .swiper-pagination-bullet {
      background: url(../../public/img/swiper-pagination-bullet.svg) !important;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: url(../../public/img/swiper-pagination-bullet-active.png) !important;
    }
  }
  


.RankingSwiper{
  overflow: visible !important;
  .Ranking_RankingBox__xgwsI{
    overflow: visible !important;
  }
}
