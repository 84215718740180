* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background: #000;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-x: hidden;
  scrollbar-width: none;
}